
.custom-card {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 3px 3px 15px 0px rgba(136, 137, 219, 0.1);
  background: #ffffff;
  border-radius: 16px;
  height: 112px;
  cursor: pointer;
  gap: 16px;
}

.custom-card.disabled-card {
  cursor: not-allowed; /* Cambiar el cursor si está deshabilitado */
  opacity: 0.6; /* Reducir opacidad para dar un efecto visual de deshabilitado */
  pointer-events: none; /* Ignorar clics en esta tarjeta */
}
  
.custom-title {
  font-family: "Museo Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #908f9a;
  margin: 0;
}

.kit-card {
  display: flex;
  align-items: center;
  box-shadow: 3px 3px 15px 0px rgba(136, 137, 219, 0.1);
  background: #ffffff;
  border-radius: 16px;
  height: 168px;
}


.kit-image {
  width: 208px;
  height: 168px;
  border-radius: 16px 0px 0px 16px;
  object-fit: cover;
}

.kit-title {
  font-size: 20px;
  line-height: 26px;
  color: #dcb632;
  margin: 0;
}

.kit-description {
  font-size: 16px;
  line-height: 24px;
  color: #908f9a;
}

.kit-notification-container {
  gap: 8px;
  font-weight: 400;
  line-height: 18px;
  border-radius: 5px;
}

.kit-selection-container{
  position: relative;
  border: 1px solid #DDDDDD;
  padding: 24px;
  border-radius: 16px;
  margin-bottom: 24px;
  background: #FFFFFF;
  width: 808px;
  min-width: 808px;
  min-height: 200px;
}

.kit-selection-container-selected{
  border: 2px solid #16BE7D;
}

.kit-selection{
  justify-content: space-between;
}

.kit-selection-img{
  width: 152px;
  height: 152px;
  gap: 0px;
  border-radius: 8px;
}

.kit-selection-description{
  width: 400px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.kit-selection-detail{
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  border:1px solid #16BE7D;
  background-color: #FFFFFF;
  color: #16BE7D;
  height: 40px;
  width: 138px; 
  border-radius: 8px;
}

.kit-selection-detail--selected{
  color: #FFFFFF;
  background-color: #16BE7D !important;
}

.kit-regular-price{
  text-decoration: line-through;
  color: #908F9A;
  margin-right: 10px;
  line-height: 24px;
  font-weight: 500;
}

.kit-discount-price{
 text-decoration: none;
 color: #171A22;
 line-height: 32px;
 font-weight: 700;
}

.most-chosen-badge {
  position: absolute;
  top: -0.75rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: #CED2FF;
  color: #456ECE;
  padding: 4px 12px;
  border-radius: 100px;
  font-weight: 600;
  line-height: 18px;
}

.modal-content-styles{
  border-radius: 15px !important;
  max-height: 600px !important;
}

.modal-dialog-styles{
  max-width: 912px !important;
}

.kit-image-section {
  display: flex;
  width: 372px;
  height: 407px;
}

.kit-image-section img {
  border-radius: 16px;
  object-fit: cover;
  width: 372px;
}

.kit-details-section {
  position: relative;
}

.btn-close-modal{
  position: absolute;
  right: -20px;
  top: -20px;
}

.kit-description-modal{
  line-height: 20px;
}

.kit-includes-title {
  color: #908F9A;
  line-height: 20px;
}

.kit-includes{
  max-height: 75px;
}

.kit-prices {
  margin-bottom: 20px;
}

.kit-select-button {
  width: 392px;
  max-height: 40px;
  font-size: 16px;
  padding: 10px;
}

.card-container-body{
  margin-inline: 2rem;
  gap: 2rem;
}


.width-container-card{
  width: 1222px;
}

@media (max-width: 1440px) {
  .width-container-card {
    width: 1203px;
  }
}

@media (max-width: 1280px) {
  .width-container-card {
    width: 1042px;
  }
}

@media (max-width: 1024px) {
  .width-container-card {
    width: 818px;
  }
}

