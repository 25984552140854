.title-orders {
    font-family: 'MuseoSans500';
    font-size: 20px;
    line-height: 26px;
    color: #171A22;
    margin-top: 48px;
    margin-bottom: 27px;
    img {
        width: 16px;
        height: 16px;
        margin-left: 4px;
    }

    span {
        font-size: 14px;
        line-height: 20px;
        color: #5AAFF1;
        margin-left: 18px;
        display: inline-flex;
        align-items: center;
    }
}
.container-list{
    background-color: #FFFFFF;
    border-radius: 16px;
    padding: 28px 32px 28px 32px;
    margin-top: 48px;
}
.titleTable{
    display: flex;
    height: 15px;  
    font-size: 14px;
    align-items: center;
    line-height: 18px;
    color: #908F9A;
    span{
       color: #171A22;
       margin-right:10px;
    }
}
.ordenName{
    width: 286px;

}
.requestName{
    width: 315px;
}
.title-table-center{
    width: 219px;
    justify-content: center;

}
.title-table-end{
    width: 184px;
    justify-content: flex-start;
    margin-left: 55px;

}
.total{
    color: #171A22;
}
.container-not-ordens{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 44px;
    img{
        margin-bottom: 16px;
        font-size: 24px;
    }
    h5{
        margin-bottom: 8px;
    }
    p{
       color: #908F9A;
       font-size: 16px;
       font-family: 'museo-sans-500';
    }
}
.select-container{
    font-size: 14px;
    color: #171A22;
}
.span-total{
    color: #908F9A;
    font-size: 14px;
}
.filter-button {
    width: 184px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px; 
    padding: 8px 16px;
    border-radius: 8px; 
    background-color: white;
    color: #5AAFF1; 
    margin-bottom:32px;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    border: 1px solid #5AAFF1
  }
  
  /* .filter-button:hover {
    background-color: #87CEEB;
    color: white; 
  } */
  
  .filter-icon {
    width: 16px;
    height: 16px;
    display: inline-block;
  }
  .container-error{
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    img{
        width: 104px;
        height: 104px;
        margin-bottom: 16px;
    }
    p{
        width: 255px;
        font-family: 'MuseoSans500';
        font-size: 16px;
        text-align: center;
        color: #171A22;
    }
}
.width-total{
    width: 106px !important; 
}
.check{
    width: 20px;
    height: 20px;
}
@media (max-width: 1921px) { 
    .width-total{
        width: 122px!important; 
    }
    .container-list{
        width: 1310px;
    }
 }
 @media (max-width: 1441px) { 
    .container-filter{
        margin-right: 92px;
    }
    .container-list{
        width: 1229px;
    }
    .title-table-end {
        width: 118px;

    }
    .check{
        width: 21px;
    }
 }
 
@media (max-width: 1281px) { 
    .container-list{
        width: 1067px;
    }
    .title-table-end {
        width: 124px;

    }
 }
@media (max-width: 1025px) { 
    .container-list{
        width: 850px;
    }
    .title-table-center {
        width: 186px;
    }
    .check{
        width: 25px ;
    }
    .title-table-end {
        width: 107px;

    }
 }

 @media (min-width: 1024px) and (max-width: 1439px) { 
    .nameSpan{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 103px;
    }
    
 }
 @media (min-width: 1440px) { 
    .requestName{
        width: 500px;
    }
    .total{
        width: 124px !important;
    }
    .status{
        margin-left: 48px !important;
    }
 }
 