.client-input-wrapper {
    position: relative;
    cursor: pointer;
  }
  
  .client-input-wrapper input {
    padding-right: 2.5rem;
    background-color: white;
    border: 1px solid #dee2e6;
    border-radius: 0.375rem;
    width: 100%;
    height: 38px;
    padding-left: 1rem;
    cursor: pointer;
  }
  
  .client-input-wrapper svg {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
  
  .client-list {
    max-height: 400px;
    overflow-y: auto;
  }

  .check-input .form-check-input {
    border: 2px solid #5AAFF1
}

.check-input .form-check-input:focus {
    outline: none;
    box-shadow: none;
    border-color: #5AAFF1;
}

.check-input .form-check-input:checked {
    background-color: #5AAFF1;
    border-color: #5AAFF1;
}

.check-input{
  padding: 20px 16px;
}

  .back-button {
    background: transparent;
    border: none;
    padding: 8px;
}

.back-button:focus {
    outline: none;
}
