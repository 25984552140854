/*font-family: 'DM Sans', sans-serif;*/
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500;700&display=swap");

@font-face {
  font-family: "MuseoSans500";
  src: local("MuseoSans500"), url(../fonts/MuseoSans-500.otf) format("opentype");
}

@font-face {
  font-family: "MuseoSans700";
  src: local("MuseoSans700"), url(../fonts/MuseoSans-700.otf) format("opentype");
}

@font-face {
  font-family: "MuseoSans400";
  src: local("MuseoSans400"), url(../fonts/MuseoSans-500.otf) format("opentype");
}

@font-face {
  font-family: "MuseoSans600";
  src: local("MuseoSans600"), url(../fonts/MuseoSans-700.otf) format("opentype");
}

@font-face {
  font-family: "DMSansBold";
  src: local("DMSansBold"), url(../fonts/DMSans-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "DMSansMedium";
  src: local("DMSansBold"), url(../fonts/DMSans-Medium.ttf) format("truetype");
}

* {
  box-sizing: border-box;
}
.css-ep1xzi-placeholder {
  padding: 0px !important;
}

.w-65 {
  width: 65%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90% !important;
}

.svg-force-hover-opacity:hover svg {
  opacity: 0.4;
}

.bg-opacity-80-seals {
  background-color: #1d222e;
  opacity: 60%;
}

input:focus {
  outline: none;
}

h2 {
  margin: 0px;
}

:root {
  --fondo: #fffdf5;
}

.opacity80 {
  opacity: 0.5;
}

.outline-profile-picture {
  border: 1.5px solid white;
}

text {
  text-align: center;
  font-family: "DMSansMedium", Arial, Helvetica, sans-serif !important;
}

.pointer {
  cursor: pointer;
}

.no-pointer {
cursor: not-allowed;
}

.cursor-default {
  cursor: default;
}

.swal2-actions {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0px;
}

.swal2-actions > button {
  width: 288px !important;
  height: 40px !important;
  border-radius: 8px !important;
  font-family: "MuseoSans700", Arial, Helvetica, sans-serif !important;
  font-size: 14px !important;
}

.pulsing {
  filter: blur(5px);
  animation-name: stretch;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-play-state: running;
}

@keyframes stretch {
  0% {
    transform: scale(1);
  }

  70% {
    transform: scale(1.04);
  }
  100% {
    transform: scale(1.02);
  }
}

.blurred {
  filter: blur(8px);
  -webkit-filter: blur(8px);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  will-change: transform;
  transform: translate3d(0, 0, 0);
}

.museo-sans-500 {
  font-family: "MuseoSans500", Arial, Helvetica, sans-serif !important;
}

.museo-sans-700 {
  font-family: "MuseoSans700", Arial, Helvetica, sans-serif !important;
}

.museo-sans-400 {
  font-family: "MuseoSans400", Arial, Helvetica, sans-serif !important;
}

.museo-sans-600 {
  font-family: "MuseoSans600", Arial, Helvetica, sans-serif !important;
}

.dm-sans-medium {
  font-family: "DMSansMedium", Arial, Helvetica, sans-serif !important;
}

.dm-sans-bold {
  font-family: "DMSansBold", Arial, Helvetica, sans-serif !important;
}

.dm-sans-500 {
  font-family: "DM Sans", Arial, Helvetica, sans-serif !important;
  font-weight: 500;
}

.dm-sans-700 {
  font-family: "DM Sans", Arial, Helvetica, sans-serif !important;
  font-weight: 700;
}

.btnCustom:hover {
  opacity: 0.5;
  transition: all 0.5s ease-out;
}

main {
  overflow-y: hidden;
  color: #171a22;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  /* font-family: 'MuseoSans500' !important;
  font-family: 'MuseoSans700' !important; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none !important;
}

.cancelBootstrap:hover {
  color: #908f9a;
}

p {
  margin: 0px !important;
}

.lightui1-shimmer {
  animation-duration: 2.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #d8d8d8;
  background-image: linear-gradient(
    to right,
    #d8d8d8 0%,
    #bdbdbd 20%,
    #d8d8d8 40%,
    #d8d8d8 100%
  );
  background-repeat: no-repeat;
}

.lightui1-shimmer div {
  background: #ffffff;
  height: 6px;
  left: 0;
  position: absolute;
  right: 0;
}

button {
  position: relative;
  cursor: pointer;
}

form {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
}
/*svg {
  cursor: pointer;
}*/

i {
  cursor: pointer;
  color: gray;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::-webkit-input-placeholder {
  color: #908f9a !important;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* HTML ELEMENTS - END */

.disabledCursor {
  cursor: not-allowed !important;
}

.form-child {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.whiteLightBg {
  background-color: #fafafc;
}

.errInput {
  border: red 1px solid;
  box-shadow: 0px 0px 5px red;
  transition: all 0.5s ease-out;
}

.text-center {
  text-align: center;
}

.btnBlueBorders {
  border-radius: 0.5rem;
  background-color: transparent;
  height: fit-content;
  border: 1px solid #456ece;
}

.btnLoginWidth {
  width: 288px !important;
  height: 40px !important;
}

.img-force-height > img {
  height: 100vh !important;
}

.img-force-height-inhert > img {
  height: inherit !important;
}

.svg-force-size > svg {
  width: inherit !important;
  height: inherit !important;
}

.svg-force-color > svg {
  fill: inherit !important;
  color: inherit !important;
}
.svg-force-color > svg > path {
  fill: inherit !important;
  color: inherit !important;
}

.common-cursor {
  cursor: auto;
}

.svg-force-path-stroke > svg > path {
  stroke: #fff;
}

.svg-force-path-stroke-grey > svg > path {
  stroke: #ddd;
}

.btnCustom {
  border-radius: 0.5rem;
  border: 0px;
  height: fit-content;
  transition: all 0.5s ease-out;
}

.btnThreeDots {
  height: 40px;
  width: 40px;
}

.rotate-deg-45 {
  transform: rotate(20deg);
}

.w400 {
  font-weight: 400;
}

.w600 {
  font-weight: 600;
}

.s8 {
  font-size: 8px !important;
}

.s9 {
  font-size: 9px !important;
}

.s10 {
  font-size: 10px !important;
}

.s12 {
  font-size: 12px !important;
}

.s13 {
  font-size: 13px !important;
}

.s14 {
  font-size: 14px !important;
}

.s16 {
  font-size: 16px !important;
}

.s18 {
  font-size: 18px !important;
}

.s20 {
  font-size: 20px !important;
}
.s22 {
  font-size: 22px !important;
}

.s24 {
  font-size: 24px !important;
}

.s26 {
  font-size: 26px !important;
}

.s30 {
  font-size: 30px !important;
}

.s32 {
  font-size: 32px !important;
}

.s34 {
  font-size: 34px !important;
}

.s40 {
  font-size: 40px !important;
}

ul {
  margin-bottom: 0px !important;
}

.loadingContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0px;
  border-radius: 0.5rem;
  background: rgba(44, 44, 44, 0.5);
}

.spinner-border-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  inset: 0px;
  background: rgba(12, 12, 12, 0.5);
  border-radius: 0.5rem;
  z-index: 20;
}

.input-container {
  position: relative;
}

.input-container > i {
  position: absolute;
  right: 20px;
  top: 5px;
  z-index: 1;
}

.input-container > select {
  position: relative;
  background: transparent;
  z-index: 2;
}

.input-container > select:active,
.input-container > select:focus {
  position: relative;
  background: transparent;
  z-index: 2;
}

.input-container > select:active ~ i,
.input-container > select:focus ~ i {
  transform: rotate(180deg);
}

.remove-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.remove-scrollbar::-webkit-scrollbar {
  display: none;
}

/* RE-USEABLE CLASSESS - END */

/*Estilos de modal*/

.modal-height {
  height: auto;
}

.modal-width {
  width: auto !important;
  max-width: unset !important;
}

.modal-radius {
  border-radius: 20px !important;
}

.modal-white-shadow {
  box-shadow: -2px 48px 38px 0px rgb(255 255 255 / 99%) inset,
    0px -20px 50px 20px rgb(255 255 255 / 90%);
  bottom: 0;
  width: 100%;
  height: 40px;
  z-index: 1900;
}

/*Estilos de los botones*/

.button-radius {
  border-radius: 20px !important;
}

.button-bootstrap-radius {
  border-radius: 0.375rem !important;
}

.button-padding {
  padding: 0.375rem 0.75rem;
}

.button-padding-2 {
  padding: 0.375rem 2rem;
}

.button-border-none {
  border: none !important;
}

.button-radius-and-border {
  border-radius: 20px !important;
  border: none !important;
}

.button-selected-1 {
  background-color: #c29ae0 !important;
  color: #f9f6fc !important;
}

.button-unselected-1 {
  background-color: #f3ebf9 !important;
  color: #c29ae0 !important;
}

.input-radius {
  border-radius: 10px !important;
}

.button-minWidth-1 {
  min-width: 180px !important;
}

.button-hover-light:hover {
  background-color: #a8d6ff !important;
  color: #fafafc !important;
}

.border-0-no-hover:hover {
  border: 0px;
}

.button-hover-light-text:hover {
  color: #a8d6ff !important;
  border-color: #a8d6ff !important;
}

.button-hover-svg-light-fill:hover > svg > path {
  fill: #a8d6ff !important;
}

.button-hover-svg-light-fill:hover > div > svg > path {
  fill: #a8d6ff !important;
}

.button-hover-svg-light-fill:hover > svg > circle {
  fill: #a8d6ff !important;
}

.button-hover-svg-light-stroke:hover > svg > path {
  stroke: #a8d6ff !important;
}

.button-hover-svg-light-stroke:hover > svg > circle {
  stroke: #a8d6ff !important;
}

.button-disabled-border {
  color: #dddddd !important;
  border-color: #dddddd !important;
  border-style: solid;
}

.button-disabled-border > svg > path {
  stroke: #dddddd !important;
  /*fill: #DDDDDD !important;*/
}

.button-disabled-border > svg > circle {
  stroke: #dddddd !important;
  /*fill: #DDDDDD !important;*/
}

.svg-margin-left > svg {
  margin-left: 10px;
}

.width-fit-content {
  width: fit-content;
}

.no-outline-input:focus {
  outline: none;
}

/*Estilos de hover*/

.hover-pointer-none {
  cursor: default;
}

.hover-pointer:hover {
  cursor: pointer;
}

.hover-color-none:hover {
  color: unset;
}

.hover-pointer-and-shadow:hover {
  cursor: pointer;
  box-shadow: 10px 10px 44px -8px rgba(210, 210, 215, 0.74);
  border-bottom: none;
  border-radius: 20px;
}

.pointer-events-none {
  pointer-events: none;
}

/*Media queries para los modals*/

@media screen and (min-width: 575px) {
  .modal-width {
    width: 350px !important;
  }
}

@media screen and (min-width: 992px) {
  .modal-width {
    width: 850px !important;
  }

  .modal-height {
    height: 530px;
  }
}

.video-padding-bottom > video {
  padding-bottom: 7px;
}

.upload-profile-picture-disable-trash-can > div > div > div > div > button {
  display: none;
}

.upload-profile-picture-zoom-input-padding > div > div > div:last-child {
  padding-left: 1rem !important;
}

.upload-profile-picture-white-circle > div > div > div > div > div {
  box-sizing: border-box;
  border: 3px solid #ffffff;
}

.upload-profile-picture-grey-background > div > div > div > div > canvas {
  filter: brightness(0.5);
}

.upload-profile-picture-input-width
  > div
  > div
  > div:last-child
  > div:first-of-type {
  width: 195px !important;
}

.swal2-title {
  font-family: "MuseoSans700", Arial, Helvetica, sans-serif !important;
  font-size: 24px !important;
}

.swal2-html-container {
  font-family: "MuseoSans500", Arial, Helvetica, sans-serif !important;
  font-size: 16px !important;
  color: #908f9a !important;
  margin-top: 8px !important;
  padding: 0px !important;
  overflow: hidden;
}

.swal2-styled {
  border-radius: 8px !important;
  margin: 0px !important;
}

.upload-profile-picture-background-width > div > div > div:first-of-type {
  width: 195px !important;
}

.LinkOpacityHover {
  opacity: 0.8;
}

.no-calendar-s17 > input[type="date"]::-webkit-calendar-picker-indicator {
  width: 17px !important;
  height: 17px !important;
}

.no-calendar > input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url("data:image/svg+xml,%3Csvg id='lg' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Crect id='bg' width='24' height='24' fill='none'/%3E%3Cpath id='vector' d='M296.631 8635.513a1.878 1.878 0 0 0-1.878-1.879h-1.878V8631.6a.469.469 0 0 0-.469-.469h-1.565a.468.468 0 0 0-.469.469v2.034h-5.007V8631.6a.469.469 0 0 0-.469-.469h-1.565a.468.468 0 0 0-.469.469v2.034h-1.878a1.878 1.878 0 0 0-1.878 1.879V8637h17.525Z' transform='translate(-275.867 -8629.13)' fill='%235aaff1'/%3E%3Cpath id='vector-2' data-name='vector' d='M279.106 8636.4v11.555a1.877 1.877 0 0 0 1.878 1.877h13.77a1.878 1.878 0 0 0 1.878-1.877V8636.4Zm6.259 9.207a.471.471 0 0 1-.469.469h-1.565a.47.47 0 0 1-.469-.469v-1.563a.47.47 0 0 1 .469-.472H284.9a.471.471 0 0 1 .469.472Zm0-3.755a.471.471 0 0 1-.469.469h-1.565a.47.47 0 0 1-.469-.469v-1.565a.47.47 0 0 1 .469-.47H284.9a.471.471 0 0 1 .469.47Zm3.755 3.755a.471.471 0 0 1-.469.469h-1.565a.47.47 0 0 1-.469-.469v-1.563a.47.47 0 0 1 .469-.472h1.565a.471.471 0 0 1 .469.472Zm0-3.755a.471.471 0 0 1-.469.469h-1.565a.47.47 0 0 1-.469-.469v-1.565a.47.47 0 0 1 .469-.47h1.565a.471.471 0 0 1 .469.47Zm3.755 3.755a.471.471 0 0 1-.469.469h-1.565a.471.471 0 0 1-.469-.469v-1.563a.47.47 0 0 1 .469-.472h1.565a.471.471 0 0 1 .469.472Zm0-3.755a.471.471 0 0 1-.469.469h-1.565a.471.471 0 0 1-.469-.469v-1.565a.47.47 0 0 1 .469-.47h1.565a.471.471 0 0 1 .469.47Z' transform='translate(-275.867 -8627.802)' fill='%235aaff1'/%3E%3C/svg%3E");
  width: 20px;
  height: 20px;
  border-width: thin;
}

.no-calendar > input[type="date"] {
  outline: none;
}

.no-calendar {
  position: relative;
}

.svg-stroke-gray > svg path {
  stroke: #908f9a;
}
.svg-stroke-blue > svg path {
  stroke: rgba(90, 175, 241, 1)
}
.no-calendar > svg {
  position: absolute;
  left: 100px;
  top: 15px;
  z-index: 0;
}

.header-shadow {
  box-shadow: 5px 5px 25px #a4a4d733;
}

.menu-shadow {
  box-shadow: 10px 10px 15px #a4a4d733;
}

.container-hover-show:hover > .hover-show-element {
  display: flex;
}
.container-hover-show > .hover-show-element {
  display: none;
}

.icon-before::before{
  width: inherit !important;
  height: inherit !important;
}

.svg-force-stroke-path > svg > path {
  stroke: inherit !important;
}

.svg-force-stroke-path > svg > rect {
  display: none;
}

.spamn {
  word-wrap: normal;
}

.cards-shadow {
  box-shadow: 3px 3px 15px #a4a4d733;
}
.tabs-shadow {
  box-shadow: 10px 10px 25px #a4a4d733;
}
.tabs-shadow:hover {
  color: #171a22;
}

.w-min-content {
  width: min-content;
}

.rounded-16 {
  border-radius: 16px;
}

.rounded-8 {
  border-radius: 8px;
}

.desc-cards-shadow {
  box-shadow: 3px 3px 15px #a4a4d726;
}

#modal--clipboard {
  display: grid;
  grid-template-columns: 0.2fr 1.5fr 0.2fr;
  grid-template-rows: 1fr;
  width: 392px;
  background-color: #e7f5f0;
  bottom: 0px;
  z-index: 50;
  border-radius: 0.5rem;
}

.opacity20 {
  opacity: 0.2;
}

.control--svg-filler-gray > svg > path {
  stroke: #908f9a;
}

.control--svg-filler-fill-gray > svg > path {
  fill: #908f9a;
  stroke: #908f9a;
}

.control--svg-filler-sky > svg > path {
  stroke: #5aaff1;
}

.test-hidden-scroll {
  overflow: auto;
}
.test-hidden-scroll-x {
  overflow-x: auto;
}
.test-hidden-scroll-x::-webkit-scrollbar {
  height: 8px;
  width: 0px;
  border: 1px solid #fff;
}
.test-hidden-scroll::-webkit-scrollbar {
  height: 8px;
  width: 0px;
  border: 1px solid #fff;
}

.test-hidden-scroll::-webkit-scrollbar-track {
  border-radius: 0;
  background: #eeeeee;
}

.test-hidden-scroll::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: #b0b0b0;
}

.hidden-y-scroll {
  overflow-y: scroll;
}

*::-webkit-scrollbar {
  display: none;
}

.hidden-y-scroll::-webkit-scrollbar {
  display: none;
}

.navbar-first-step {
  z-index: 2000 !important;
  pointer-events: none;
}

.blurred-modal-bottom {
  content: "";
  position: absolute;
  width: 100%;
  height: 20px;
  background-color: black;
  filter: blur(10px);
  z-index: 1;
}

.swal2-popup {
  width: 496px !important;
  min-height: 410px !important;
  border-radius: 16px !important;
  padding: 32px 40px 32px 40px !important;
}

.swal2-cancel {
  background-color: white !important;
  color: #5aaff1 !important;
  border: 1px solid #5aaff1 !important;
}

.swal2-cancel:hover {
  background-color: white !important;
  color: #5aaff1 !important;
  border: 1px solid #5aaff1 !important;
}

.swal2-confirm {
  background-color: #5aaff1 !important;
  color: white !important;
}

.swal2-icon {
  margin: 0px !important;
  justify-self: center;
  width: 92px !important;
  height: 92px !important;
}

.swal2-styled.swal2-confirm:focus {
  box-shadow: none !important;
}

.swal2-actions:not(.swal2-loading) .swal2-styled:hover {
  background-image: none !important;
}

.swal2-x-mark > .swal2-x-mark-line-left,
.swal2-x-mark > .swal2-x-mark-line-right {
  background-color: initial !important;
  display: none !important;
}
.swal2-icon.swal2-error {
  border-color: 0px;
  border: none;
  color: initial;
  background-image: url("data:image/svg+xml,%3Csvg id='ic-x-circle' xmlns='http://www.w3.org/2000/svg' width='92' height='92' viewBox='0 0 92 92'%3E%3Crect id='bg' width='92' height='92' fill='none'/%3E%3Ccircle id='vector' cx='46' cy='46' r='46' fill='%23fef0f0'/%3E%3Cpath id='vector-2' data-name='vector' d='M-5833.415-6733.02l28.139 28.139' transform='translate(5865.34 6764.948)' fill='none' stroke='%23f06e69' stroke-linecap='round' stroke-width='6.5'/%3E%3Cpath id='vector-3' data-name='vector' d='M-5805.275-6733.02l-28.139 28.139' transform='translate(5865.34 6764.948)' fill='none' stroke='%23f06e69' stroke-linecap='round' stroke-width='6.5'/%3E%3C/svg%3E") !important;
}

.input-styless {
  border: 0px;
  background: transparent;
}

.text-onhover-bold:hover {
  font-family: "MuseoSans700", Arial, Helvetica, sans-serif;
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) and (min-height: 720px) and (max-height: 768px) {
  .tabs-top {
    top: 80px;
  }
}
