.containerProduct {
    padding: 30px 0px;
}

.title {
    font-family: 'MuseoSans700';
    font-size: 24px;
    line-height: 40px;
    color: #171A22;
    margin-bottom: 24px;
    height: 40px;
}

.subtitle {
    font-family: 'MuseoSans700';
    font-size: 26px;
    line-height: 32px;
    color:#171A22;

    span {
        color: #5AAFF1;
        font-size: 14px;
        margin-left: 16px;
    }
}
.container-error{
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    img{
        width: 104px;
        height: 104px;
        margin-bottom: 16px;
    }
    p{
        width: 255px;
        font-family: 'MuseoSans500';
        font-size: 16px;
        text-align: center;
        color: #171A22;
    }
}