

.modal-contentProducts {
    background: white;
    padding: 20px;
    padding-top: 32px;
    padding-bottom: 32px;
    border-radius: 10px;
    width: 496px;
    max-width: 600px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-header {
    flex-direction: column;
    align-items: flex-start !important;
}

.modal-header h2 {
    margin: 0;
}



.color {
    color: #908F9A;
}

.order-details,
.productos {
    margin-bottom: 15px;
}

.producto-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    height: 87px;
   
}

.producto-container img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    background: #FDFBF5;
}

.producto-info {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.modal-footer {
    text-align: center;
    display: flex !important;
    justify-content: center !important;
}

.container-button {
    padding: 10px 20px;
    background-color: #5AAFF1;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 288px;
   
}



.overflow {
    overflow-y: auto;
    height: 286px;
    padding: 14px;
    scrollbar-width: thin;
    scrollbar-color: #908F9A ;
}

.overflow::-webkit-scrollbar {
    width: 4px;
    height: 80px;
}

.overflow::-webkit-scrollbar-thumb {
    background-color: #908F9A;
    border-radius: 10px;
}

.overflow::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 10px;
}

.overflow::-webkit-scrollbar-thumb:hover {
    background-color: #908F9A;
}