.container-shipping{
    min-height:196px;
}

.container-payment-method{
    height: 180px;
}

.container-card{
    max-width: 496px;
    box-shadow: 3px 3px 15px 0px #8889DB1A;
    padding: 32px;
    border-radius: 16px;
    background-color: #FFF;
}

.payment-btn{
    width: 288px;
    height: 40px;
    border-radius: 8px;
    color: white;
}

.toggle-button {
    border: none;
    color: #6667C2;
    cursor: pointer;
    border-radius: 8px;
    font-size: 14px;
    background-color: #DBDBF4;
}

.toggle-button.active {
    background-color: #FFF;
    font-weight: bold;
}

.container-card .address-info{
    color: #908F9A;
    line-height: 20px;
}

.container-btn-toggle{
    background-color: #DBDBF4;
    border-radius: 8px;
    height: 40px;
}

.custom-radio {
    appearance: none;
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #A2A3A7;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    position: relative;
    background-color: white;
}

.custom-radio:checked {
    border-color:#5AAFF1;
}

.custom-radio:checked::after {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    background-color: #5AAFF1;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.input-card {
    width: 343px;
    height: 80px; 
    padding: 16px;
}

/* Custom styles for the shipping form */
.shipping-form .form-control {
    padding: 0.75rem 1rem;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #fff;
  }
  
  .shipping-form .form-control:disabled {
    background-color: #f8f9fa;
    color: #6c757d;
  }
  
  .shipping-form .dropdown-toggle::after {
    display: none;
  }
  
  .shipping-form .bi-chevron-down {
    color: #0d6efd;
  }
  
  .shipping-form .alert-info {
    background-color: #f8f9ff;
    border: none;
    border-radius: 8px;
    padding: 1rem;
  }
  
  .shipping-form .dropdown-menu {
    padding: 0.5rem;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .shipping-form .dropdown-item {
    padding: 0.75rem 1rem;
    border-radius: 6px;
  }
  
  .shipping-form .dropdown-item:hover {
    background-color: #f8f9fa;
  }


  .amount-input-wrapper {
    position: relative;
    margin: 0 40px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #DDD;
}

.amount-display {
    font-size: 40px;
    color: #000;
    position: absolute;
    pointer-events: none;
}

.decimal-part {
    font-size: 16px;
    vertical-align:middle; /* Eleva los decimales */
    margin-left: 2px;
}

.amount-display.empty {
    color: #9e9e9e;
}

.amount-input {
    width: 100%;
    border: none;
    font-size: 40px;
    text-align: center;
    z-index: 1;
    line-height: 48px;
    outline: none;
    caret-color: black;
    height: 100%;
    user-select: none;
}

.amount-input:focus {
    outline: none;
}

.form-switch .form-check-input {
    width: 40px !important;
    height: 24px !important;
    cursor: pointer;
    background-color: #C9E3FA;
    border-color: #C9E3FA;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e") !important;
}

.form-switch  .form-check-input:checked {
    background-color: #5AAFF1;
    border-color: #5AAFF1;
}

.form-switch .form-check-input:focus {
    outline: 0;
    box-shadow: none;
  }

.form-switch .form-check-input:focus-visible {
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  }

  .notification-border{
    box-shadow: 0px 1px 17px 0px #171A220F;
  }
