.containerCategoriesSearch {
    padding: 24px 60px;;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    /* width: calc(100% + 96px);
    margin-left: -48px; */
    box-shadow: 10px 10px 25px 0px #A4A4D733;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    top: -4px;
    position: relative;
    }

.categories {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 15px;
}

.category {
    background-color: #ECF5FE;
    color: #104E7E;
    border: none;
    border-radius: 8px;
    padding: 10px 15px;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    font-family: 'MuseoSans500';
    transition: background-color 0.3s ease;
}

.category.selected {
    background-color: #C9E3FA;
    color: #104E7E;
    font-family: 'MuseoSans700';
}

.subcategories {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.subcategory {
    background-color: #dff3ff;
    border: none;
    border-radius: 6px;
    padding: 8px 12px;
    color: #4a90e2;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.subcategory:hover {
    background-color: #4a90e2;
    color: white;
}

.search-bar {
    display: flex;
    align-items: center;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 4px;
    max-width: 300px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}


.search-input {
    width: 392px;
    height: 40px;
    padding: 4px 16px 4px 16px;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-size: 14px;
    box-sizing: border-box;
}
.search-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    color: #00a1e5;
    padding-right: 8px;
    
}

.search-button:hover {
    color: #007bbd;
}



.containerSearch {
    img {
        position: relative;
        right: 35px;
        
    }
}
.container-loadding{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (min-width: 1280px) {
    .containerCategoriesSearch {
        width: calc(100% + 128px);
        margin-left: -64px;
        }
}

.visible {
    position: sticky;
    top: 0;
    transition: transform 0.3s ease, opacity 0.3s ease;
    transform: translateY(0);
    opacity: 1;
    z-index: 2;
  }
  
  .hidden {
    transition: transform 0.3s ease, opacity 0.3s ease;
    transform: translateY(-20px);
    opacity: 0;
    pointer-events: none;
  }
