.container-overflow{
    height: 298px;
    padding: 0px 44px;
    margin-top: 24px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #908F9A ;
}
.container-overflow::-webkit-scrollbar {
    width: 4px;
    height: 36px;
}

.container-overflow::-webkit-scrollbar-thumb {
    background-color: #908F9A;
    border-radius: 10px;
}

.container-overflow::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 10px;
}

.container-overflow::-webkit-scrollbar-thumb:hover {
    background-color: #908F9A;
}
.container-img{
    display: flex;
    justify-content:center ;
    img{
        width:116px ;
        height:36px ;
        margin-bottom: 18px;
    }
}
.text-payment-modal{
    padding-top: 24px;
    text-align: center;
    color: #908F9A;
}
.margin-top-payment{
    margin-top: 16px !important;
}
.margin-button-payment{
    margin-bottom: 8px !important;
}