.modal-content-styles{
max-height: 800px !important;
min-height: 500px !important;
}

.date-input-wrapper {
    position: relative;
}

.custom-date-input {
    width: 100%;
    height: 38px;
    padding: 8px 12px;
    border: 1px solid #dee2e6;
    border-radius: 0.375rem;
    cursor: pointer;
}

.custom-date-input::-webkit-datetime-edit {
    color: transparent;
}

.custom-date-input:focus::-webkit-datetime-edit {
    color: #212529;
}

.custom-date-input:not([value=""])::-webkit-datetime-edit {
    color: #212529;
}

.custom-date-input::before {
    content: attr(placeholder);
    color: #6C757D;
    position: absolute;
    left: 12px;
}

.custom-date-input:focus::before,
.custom-date-input:not([value=""])::before {
    content: "";
}

.custom-date-input::-webkit-calendar-picker-indicator {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
}

.calendar-icon {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    pointer-events: none;
}

.calendar-icon svg {
    pointer-events: none;
}
  
  .status-button {
    width: 210px;
    height: 34px;
    padding: 0 12px;
    border-radius: 20px;
    background: white;
    border: 1.5px solid #5AAFF1;
    color: #5AAFF1;
    cursor: pointer;
    line-height: 18px;
    transition: all 0.2s;
}

.status-button.active {
    background: #5AAFF1;
    color: white;
}

.filter-button-modal {
    width: 288px;
    height: 40px;
    border-radius: 8px;
    cursor: pointer;
}

.filter-button-primary {
    background: #5AAFF1;
    color: white;
    border: none;
}

.filter-button-secondary {
    background: white;
    border: 1px solid #F06E69;
    color: #F06E69;
}

.form-label {
 line-height: 20px;
}