.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-contentProducts {
    background: white;
    padding: 20px;
    padding-top: 32px;
    border-radius: 10px;
    width: 600px;
    max-width: 600px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-header {
    flex-direction: column;
    align-items: flex-start!important;
}

.modal-header h2 {
    margin: 0;
}



.color-payment {
    color: #171A22;
}

.container-check-blue{
    display: flex;
    align-items: center;
    flex-direction: column;
    img{
        height:104px;
        width: 104px;
    }
    
}
.text-payment{
    color:  #908F9A;
    text-align: center;
    padding: 0px 20px;
}
.modal-footer {
    text-align: center;
    display: flex !important;
    align-items: center !important;
    flex-direction: column;
}
.color-button{
    color: #5AAFF1;
    cursor: pointer;
}
.container-button {
    padding: 10px 20px;
    background-color: #5AAFF1;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 288px;
}

.container-button-seconds{
    padding: 10px 20px;
    background-color: white;
    color: #5AAFF1;
    border-color:#5AAFF1;
    border: 1px solid;
    border-radius: 5px;
    cursor: pointer;
    width: 288px;
}

.overflow {
    overflow-y: auto;
    height: 219px;
    padding: 14px;
    scrollbar-width: thin;
    scrollbar-color: #908F9A ;
}

.overflow::-webkit-scrollbar {
    width: 4px;
    height: 80px;
}

.overflow::-webkit-scrollbar-thumb {
    background-color: #908F9A;
    border-radius: 10px;
}

.overflow::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 10px;
}

.overflow::-webkit-scrollbar-thumb:hover {
    background-color: #908F9A;
}
.container-close {
    display: flex;
    width: 100%;
    justify-content: end;
    padding-bottom: 10px;

    img {
        cursor: pointer;
        height: 20px;
        width: 20px;
    }
}