.container-modal-Schedules{
    width: 496px;
    height: 406px;
}
.container-time{
    border-bottom: 1px solid #DDDDDD; 
     height: 32px;
     margin-bottom: 8px;

}
.container-button-Schedules{
    margin-top: 32px;

}