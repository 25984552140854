.product-card {
    width: 288px;
    height: 382px;
    border-radius: 16px;
    overflow: hidden;
    transition: box-shadow 0.3s ease;
    background-color: #fff;
    box-shadow: 3px 3px 15px 0px #8889DB1A;

}

.product-card:hover {
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
}

.product-image {
    display: flex;
    width: 100%;
    height: 140px;
    object-fit: cover;
    justify-content: center;
    background-color: #FDFBF5;
}

.product-title {
    font-family: 'MuseoSans500';
    font-size: 16px;
    line-height: 24px;
    color: #171A22;
    margin-bottom: 0px;
}

.product-description {
    font-size: 16px;
    color: #171A22;
    line-height: 20px;
    font-family: 'MuseoSans500';

}

.product-price {
    font-size: 20px;
    font-weight: 700;
    color: #171A22;
    padding: 0px 20px 20px 20px;
    line-height: 26.04px;
}

.product-button {
    width: 100%;
    padding: 10px;
    border: none;
    background-color: #FFFFFF;
    color: #5AAFF1;
    border: 1px solid #5AAFF1;
    font-size: 1rem;
    height: 40px;
    display: flex;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    justify-content: center;
    align-items: center;
}

.product-button2 {
    width: 100%;
    background-color: #FFFFFF;
    color: #171A22;
    border: 1px solid #5AAFF1;
    font-size: 16px;
    height: 40px;
    display: flex;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    justify-content: center;
    align-items: center;
}

.product-button:hover {
    background-color: #5AAFF1;
    color: #FFFFFF;
    font-family: 'MuseoSans700';
    font-size: 14px;
    line-height: 20px;
}

.buttonTrashAndMinus {
    height: 100%;
    border: none;
    width: 40px;
    background-color: #ffffff;
    border-right: 1px solid #5AAFF1;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px; 
    transition: border-color 0.3s ease;
}

.buttonPlus {
    height: 100%;
    border: none;
    border-left: 1px solid #5AAFF1;
    background-color: #ffffff;
    width: 40px;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px; 
}

.buttonTrashAndMinus:hover{
    background-color: #F06E69;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    z-index: 1;
    height: 40px;
    margin-left: -1px;
    border-color: #F06E69;
}