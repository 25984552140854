.title-orders {
    font-family: 'MuseoSans500';
    font-size: 20px;
    line-height: 26px;
    color: #171A22;
    margin-top: 48px;
    margin-bottom: 27px;

    img {
        width: 20px;
        height: 20px;
        margin-left: 8px;
    }

    span {
        cursor: pointer;
        font-size: 14px;
        line-height: 20px;
        color: #5AAFF1;
        margin-left: 18px;
    }
}

.container-orderkit {
    background-color: #FFFFFF;
    border-radius: 16px;
    padding: 28px 32px 28px 32px;

}

.titleTable {
    display: flex;
    height: 15px;
    font-size: 12px;
    line-height: 18px;
    color: #908F9A;

    span {
        color: #171A22;
        margin-right: 3px;
    }
}

.orden {
    width: 286px;

}

.title-table-center {
    width: 184px;
    justify-content: center;

}

.title-table-end {
    width: 184px;
    justify-content: end;
    margin-right: 25px;

}

.total {
    color: #171A22;
    font-family: 'MuseoSans500';
}

.container-not-ordens {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 44px;

    img {
        margin-bottom: 16px;
        font-size: 24px;
    }

    h5 {
        margin-bottom: 8px;
        font-size: 24px;
    }

    p {
        color: #908F9A;
        font-size: 16px;
    }
}

@media (max-width: 1441px) {
    .container-orderkit {
        width: 1203px;
    }
}

@media (max-width: 1281px) {
    .container-orderkit {
        width: 1044px;
    }
}

@media (max-width: 1025px) {
    .container-orderkit {
        width: 818px;
    }

}