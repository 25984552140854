.skeleton-box {
    background: #e0e0e0;
    border-radius: 4px;
    animation: skeleton-loading 1.5s infinite;
  }
  
  @keyframes skeleton-loading {
    0% {
      background-color: #e0e0e0;
    }
    50% {
      background-color: #f0f0f0;
    }
    100% {
      background-color: #e0e0e0;
    }
  }
  
  .product-card-skeleton {
    width: 288px;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .product-image-skeleton {
    width: 100%;
    height: 200px;
  }
  
  .product-title-skeleton {
    width: 60%;
    height: 20px;
    margin-bottom: 8px;
  }
  
  .product-description-skeleton {
    width: 80%;
    height: 15px;
    margin-bottom: 16px;
  }
  
  .product-price-skeleton {
    width: 30%;
    height: 20px;
    margin: 16px 0;
  }
  
  .product-button-skeleton {
    width: 100%;
    height: 40px;
  }
  