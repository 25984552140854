.scrollLocation {
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #908F9A ;
    height: 364px;
}

.scrollLocation::-webkit-scrollbar {
    width: 4px;
    height: 80px;
}

.scrollLocation::-webkit-scrollbar-thumb {
    background-color: #908F9A;
    border-radius: 10px;
}

.scrollLocation::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 10px;
}

.scrollLocation::-webkit-scrollbar-thumb:hover {
    background-color: #908F9A;
}